.multiSelectSearchContainer {
  .multiSelectHeader {
    background-color: #ddd;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc
  }

  .multiSelectList {
    float: left;
    padding: 0px;
    width: 100%;


    ul {
      border :1px solid #ccc !important;
      border-radius: none;
    }

    li {
      border-bottom :1px solid #ccc !important;
      padding: 8px 16px;

      .list-container {
        display: flex;
        align-items: center;
        width: 36px;
      }
     }
  }

  .multiSelectListItem {
    margin-bottom: 0;
    padding: 4px 10px!important;
  }
  h4 {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 18px !important;
    margin-top: 4px !important;
  }
  h3 {
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 18px !important;
    margin-top: 4px !important;
  }
  .switchImage {
    margin: auto;
    padding-left: 4%;
    text-align: center;
  }

  .multiSelectFilter {
    float: left;
    height: 32px;
    padding: 0px!important;
    width: 100%;
  }

  .selectionRow a:first-child {
    margin-left: 6px;
    margin-right: 6px;
  }

  .selectionRow a:last-child {
    margin-left: 6px;
  }

  .selectionRow {
    margin-top: 6px;
  }

  .selectionRow button {
    padding-left: 2px;
    padding-right: 2px;
  }
  .dragHandle {
    display: flex;
    align-items: center;
    width: 24px;
  }
  .deleteSelectedEvent {
    border: none;
  }
}

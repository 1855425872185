h4 {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px !important;
  margin-top: 4px !important;
  font-family: "RingsideCondensed", sans-serif;
}
h3 {
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px !important;
  margin-top: 4px !important;
  font-family: "RingsideCondensed", sans-serif;
}

.title-text {
  color: #2a2d3b !important;
  font-family: "RingsideCondensed", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
}

.paragraph-text {
  color: #da0d50 !important;
  font-family: "RingsideCondensed", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 325;
  margin: 0 !important;
}
.card-container {
  background: #f0f0f0 !important;
  margin: 24px 0 !important;

  h3,
  h4 {
    color: #2a2d3b !important;
  }
  .title-wrapper {
    flex-wrap: nowrap;

    > div {
      padding: 0 !important;
    }
  }
}

.switch-button {
  display: block;
}

.inline-switch-button {
  margin-top: 4px;
}

.custom-switch-button {
  display: block;
}

.inline-custom-switch-button {
  margin-top: 4px;
}

.inline-custom-switch-button {

  .ant-switch-checked {
    background-color: aqua;
  }
}
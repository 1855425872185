
.event-details-container {
  button {
    border: none;
    background-color: #0078D4;
    color: #F0F0F0;
    border-radius: 3px;
    padding: 6px 12px;
    font-family: Arial;
    font-size: 14px;
  }
  .title-text {
    color: #2A2D3B !important;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
  }
  .paragraph-text {
    color: #2A2D3B !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 325;
  }
  .card-container {
    background:#F0F0F0;
    margin: 24px 0;
    h4 {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin-top: 4px;
    }
    h3 {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin-top: 4px ;
    }
    h3,h4 {
      color: #2A2D3B !important;
    }
    .title-wrapper {
      flex-wrap: nowrap;
      > div {
        padding: 0 !important;
      }
    }
  }
}

.action-container {
  display: flex;
  gap:10px;
  flex-wrap: wrap;

  button {
    background-color: #0078D4;
    color: #F0F0F0;
    border-radius: 3px;
  }

  .add-photo-container {
    background-color: #D9D9D9;
    display: flex;
    width: 150px;
    height: 84.091px;
    padding: 22px 55px;
    align-items: center;
    gap: 10px;
  }

  .ant-upload {
    display: flex;
    width: 285px;
    height: 144px;
    padding: 10px 16px;
    align-items: center;
    gap: 135px;
  }
  
  .add-photo-container-mobile {
    display: flex;
    padding: 100px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}
.replaceBtn {
  background-color: #0078D4 !important;
  color: #F0F0F0 !important;
  border-radius: 3px;
}
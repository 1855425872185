.logoContainer {
  padding-top: 25px;
  padding-bottom: 25px;
}

.logoPreview {
  background-color: #DDD;
  height: 100px;
  width: 300px;
}

.primaryButton {
  background-color: #0078D4;
  color: #fff;
}

.deleteButton {
  background-color: #DA0D50;
  color: #fff;
}

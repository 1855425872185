.eventsLayoutBackground {
  background: #fff;
  padding: 24px;
  min-height: 600px;
}

.events-content {
  margin: 24px 16px 0;
}

.updateActionButton, .deleteActionButton {
  margin-left: 12px;
}

.contentContainer {
  margin: 0px 24px 24px;
}

.menu {
  background-color: #0078D4!important;
  height: 50px!important;
  display: flex;
  padding: 15px 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  list-style-type: none;
}

.menu li {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
}

.headline span {
  color: #2A2D3B;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
  font-family: 'RingsideCondensed', sans-serif;
}

.title {
  color: #2A2D3B!important;
  font-size: 32px!important;
  font-style: normal!important;
  font-weight: 400!important;
  line-height: 38px!important;
  font-family: 'RingsideCondensed', sans-serif;
}

.eventLayout {
  background-color: #fff!important;
}

.contentContainer h2 {
  font-family: 'RingsideCondensed', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400!important;
  line-height: 38px;
}

.button-wrapper {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
}

.updateActionButton, .deleteActionButton, .updateActionButton span, .deleteActionButton span {
  font-size: 14px!important;;
  font-style: normal!important;;
  font-weight: 400!important;;
  line-height: 20px!important;;
  color: #F0F0F0!important;;
  font-family: 'RingsideCondensed', sans-serif;
}

.recomondedEventsContainer {
  .card-container {
    background:#F0F0F0;
    margin: 24px 0;

    h3,h4 {
      color: #2A2D3B;
    }

    h3 {
      font-family: "Ringside Condensed";
      font-size: 20px ;
      font-style: normal;
      font-weight: 400 ;
      line-height: 24px ;
      a {
        color:#0078D4;
      }
    }

    img {
      display: flex;
      width: 150px;
      height: 84.091px;
      align-items: center;
      gap: 10px;
      background-color: #D9D9D9;
    }

    .grey-title {
      color: #2A2D3B !important;
      margin-left: 10px;
    }
    .title-text {
      color: #2A2D3B !important;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: "Ringside Condensed";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
    }

    .paragraph-text {
      color: #DA0D50;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: "Ringside Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 325;
      margin-top: 10px;
    }
  }
}

.genres-wrapper{
  .delete-btn {
        background-color: #DA0D50;
      }
  
      .delete-all-btn {
        background-color: #DA0D50;
        margin-bottom: 20px;
        color: #FFFFFF;
        position: absolute;
        right: 24px;
        top: 12px;
      }
  
      h4 {
        font-size: 12px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 18px !important;
        margin-top: 4px !important;
      }
      h3 {
        font-size: 20px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 18px !important;
        margin-top: 4px !important;
      }
  
      button {
        border: none;
        border-radius: 3px;
        padding: 6px 12px;
        font-family: Arial;
        font-size: 14px;
      }
  
    .events-content {
      margin: 24px 16px 0;
    }
  
    .updateActionButton, .deleteActionButton {
      margin-left: 12px;
    }
  
    .title-text {
      color: #2A2D3B !important;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: "Ringside Condensed";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
    }
  
    .paragraph-text {
      color: #DA0D50;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: "Ringside Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 325;
    }
    .card-container {
      background:#F0F0F0;
      margin: 24px 0;
  
      h3,h4 {
        color: #2A2D3B;
      }
  
      h3 {
        font-family: "Ringside Condensed";
        font-size: 20px ;
        font-style: normal;
        font-weight: 400 ;
        line-height: 24px ;
        a {
          color:#0078D4;
        }
      }
  
      img {
        display: flex;
        width: 150px;
        height: 84.091px;
        align-items: center;
        gap: 10px;
        background-color: #D9D9D9;
      }
  
      .grey-title {
        color: #2A2D3B !important;
        margin-left: 10px;
      }
    }
    .video-url {
      margin-bottom: 6px !important;
    }
    .action-container {
      display: flex;
      gap:10px;
      flex-wrap: wrap;
  
      button {
        background-color: #0078D4;
        color: #F0F0F0;
        border-radius: 3px;
      }
  
      .add-photo-container {
        background-color: #D9D9D9;
        display: flex;
        width: 150px;
        height: 84.091px;
        padding: 22px 55px;
        align-items: center;
        gap: 10px;
      }
      .add-photo-container-mobile {
        display: flex;
        padding: 100px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
      }
    }
  }

  .container {
    justify-content: space-between;
    align-self: flex-end;
    display: flex;
  }

  .mainContainer {
    align-self: flex-end;
  }